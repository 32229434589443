
import {
    ADD_TO_CART,
    ADD_TO_CART_QUANTITY,
    ADD_TO_WISHLIST,
    REMOVE_FROM_CART,
    UPDATE_PRODUCT_QUANTITY,
    FINAL_PAYMENT,
    MOVE_WISHLISTITEM_TO_CART,
    REMOVE_FROM_WISHLIST,
    DELETE_USER,
    ADD_NEW_USER,
    INITIALIZE_CART,
    UPDATE_SHIPPING_COST,
    ORDER_CODE,
    CLEAR_CART,
    SET_RETIRE_POINT,

} from '../actions/types';

import { getCart, saveCart } from '../helpers/localStorage'

//initial data
let cartData = getCart();

let wishlistData = [
   {
      productID: 51,
      image: "women/15-item-a.jpg",
      name: 'long dress',
      price: 60,
      quantity: 1,
      totalPrice: 60
   }
]
let collaborationData = [
   {
      id: 1,
      image: "user-2.jpg",
      name: "Lissa Roy",
      email: "lissa@example.com",
      access: "Read"
   },
   {
      id: 2,
      image: "user-3.jpg",
      name: "Jaswinder Kaur",
      email: "jass@example.com",
      access: "Admin"
   },
   {
      id: 3,
      image: "user-2.jpg",
      name: "John Doe",
      email: "John@example.com",
      access: "Read"
   },
   {
      id: 4,
      image: "user-1.jpg",
      name: "Ritesh Bajaj",
      email: "ritesh@example.com",
      access: "Write"
   },
   {
      id: 5,
      image: "user-5.jpg",
      name: "Dimple Bhagtani",
      email: "dimple@example.com",
      access: "Admin"
   },
   {
      id: 6,
      image: "user-3.jpg",
      name: "Sam Akhtar",
      email: "akhtar@example.com",
      access: "Admin"
   }
]
const TAX = 0;
const SHIPPING = 0;
const ORDERCODE = 0;

const INITIAL_STATE = {
    cart: cartData,
    wishlist: wishlistData,
    tax: TAX,
    shipping: SHIPPING,
    orderCode: ORDERCODE,
    receiptProducts: null,
    collaborationData: collaborationData,
    retirePoint: null,
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
      
        case ADD_TO_CART:
           
            let product = action.payload;

            let newProductData = {
                ID: product.id,
                productID: product.objectID,
                image: product.image,
                name: product.name,
                description: product.description,
                quantity: product.minimumAmount,
                price: product.price,
                totalPrice: product.totalPrice,
                discountLabelFirstText: product.discountLabelFirstText,
                discountLabelSecondText: product.discountLabelSecondText,
                haveDiscount: product.haveDiscount,
                minimumAmount: product.minimumAmount,
                priceWithDiscount: product.priceWithDiscount,
            }

            var returnState = {
                ...state,
                cart: [...state.cart, newProductData],
                totalPrice: state.totalPrice + newProductData.price
            }

            saveCart(returnState.cart);

            return returnState;


        case ADD_TO_CART_QUANTITY:

            let productQuantity = action.payload;

            console.log("ecommerceReducer.js ADD_TO_CART_QUANTITY: ", productQuantity);

            let newproductQuantityData = {
                ID: productQuantity.id,
                productQuantityID: productQuantity.objectID,
                image: productQuantity.image,
                name: productQuantity.name,
                description: productQuantity.description,
                quantity: productQuantity.quantity,
                price: productQuantity.price,
                totalPrice: productQuantity.totalPrice,
                discountLabelFirstText: productQuantity.discountLabelFirstText,
                discountLabelSecondText: productQuantity.discountLabelSecondText,
                haveDiscount: productQuantity.haveDiscount,
                minimumAmount: productQuantity.minimumAmount,
                priceWithDiscount: productQuantity.priceWithDiscount,
            }

            return {
                ...state,
                cart: [...state.cart, newproductQuantityData],
                totalPrice: state.totalPrice + newproductQuantityData.price
            }

       
            

      



      // add product to wishlist
      case ADD_TO_WISHLIST:
         let wishlistItem = action.payload;
         let newWishlistItem = {
            productID: wishlistItem.objectID,
            image: wishlistItem.image,
            name: wishlistItem.name,
            quantity: 1,
            price: wishlistItem.price,
            totalPrice: wishlistItem.price,
         }
         return {
            ...state,
            wishlist: [...state.wishlist, newWishlistItem],
         }


      // move wishlist product to cart	
      case MOVE_WISHLISTITEM_TO_CART:
         let data = state.wishlist;
         for (const wishlistItem of data) {
            let newItem = {
               productID: wishlistItem.objectID,
               image: wishlistItem.image,
               name: wishlistItem.name,
               quantity: 1,
               price: wishlistItem.price,
               totalPrice: wishlistItem.totalPrice,
            }
            state.cart.push(newItem)
         }
         return {
            ...state,
            cart: [...state.cart],
            wishlist: []
         }



      // delete product from wishlist
      case REMOVE_FROM_WISHLIST:
         let deleteItem = action.payload;
         let wishlist = state.wishlist.filter((wishlistItem) => wishlistItem.productID !== deleteItem.productID)
         return {
            ...state,
            wishlist
         }




      // update product item quantity
        case UPDATE_PRODUCT_QUANTITY:

        let newData = action.payload.cartItem;

        let newCartData = [];
        for (const cartItem of state.cart) {
            if (cartItem.productID === newData.productID) {

                var totalPrice = 0;
                const newQuantity = action.payload.newQuantity;

                if (cartItem.haveDiscount) {

                    if (cartItem.minimumAmount > 1) {


                        const minimumAmount = cartItem.minimumAmount;

                        const remainder = newQuantity % minimumAmount;
                        const result = newQuantity / minimumAmount;

                        console.log("newQuantity: " + newQuantity + " minimumAmount: " + minimumAmount + " remainder: " + remainder
                            + " result: " + result);

                        totalPrice = cartItem.priceWithDiscount * (newQuantity-remainder);

                        if (remainder > 0) {
                            totalPrice += cartItem.price * remainder;
                        }
                    }
                    else {
                        //descuento normal
                        console.log("newQuantity: " + newQuantity + " priceWithDiscount: " + cartItem.priceWithDiscount );
                        totalPrice = cartItem.priceWithDiscount * newQuantity;
                    }
                    
                }
                else {
                    totalPrice = cartItem.priceWithDiscount * newQuantity;
                }

                

                cartItem.quantity = newQuantity;
                cartItem.totalPrice = totalPrice;
            }
            newCartData.push(cartItem)
        }


            var returnState = {
                ...state,
                cart: newCartData,
                totalPrice: state.totalPrice
            }

            saveCart(returnState.cart);


            return returnState;


      // remove product to cart	
      case REMOVE_FROM_CART:
         let removeItem = action.payload;
         let cart = state.cart.filter((cartItem) => cartItem.productID !== removeItem.productID)

            var returnState = {

            ...state,
            cart,
            totalPrice: state.totalPrice - removeItem.price
            }


            saveCart(returnState.cart);


            return returnState;



      // final statement (invoice)	
      case FINAL_PAYMENT:
         let checkOutProducts = state.cart;
         return {
            ...state,
            receiptProducts: checkOutProducts,
            cart: []
         }



      // delete user (admin-panel)	
      case DELETE_USER:
         let removeUser = action.payload;
         let NewUserList = state.collaborationData.filter((listItem) => listItem.id !== removeUser.id)
         return {
            ...state,
            collaborationData: NewUserList
         }



      // add product to cart 
      case ADD_NEW_USER:
         let newUser = action.payload;
         let newUserInfo = {
            name: newUser.name,
            email: newUser.email,
            access: newUser.access,
            image: "user-edit.png",
         }
         return {
            ...state,
            collaborationData: [...state.collaborationData, newUserInfo]
         }

    case INITIALIZE_CART:

        let cart2 = action.payload;

        return {
            ...state,
            cart: [...cart2],               
        }

        case UPDATE_SHIPPING_COST:

            let shippingCost = action.payload;

            return {
                ...state,
                shipping: shippingCost,

            }

        case ORDER_CODE:

            let orderCode = action.payload;

            return {
                ...state,
                orderCode: orderCode,

            }


        case SET_RETIRE_POINT:
            let retirePoint = action.payload;
            return {
                ...state,
                retirePoint: retirePoint,
            };

        case CLEAR_CART:


            return {
                ...state,
                cart: [],
            }


      // default case	
      default:
         return { ...state }
   }
}