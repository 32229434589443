/**
 * site header two component
 */
/* eslint-disable */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Link } from 'react-router-dom';
// components
import HeaderMenu from "./HeaderMenu";
import Cart from "./Cart";
import Logout from "./Logout";
import FixedHeader  from '../headers/FixedHeader';
import SidebarMenu from '../sidebar/sidebar';
import AppConfig from '../../../constants/AppConfig';
import SearchBoxV2 from './SearchBoxV2';
import SearchBox from './SearchBox';
import SearchBoxV3 from './SearchBoxV3';


import axios from 'axios';


class HeaderTwo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fixedHeader: false,
            navLinks: [],
        }

    
    }

    componentDidMount() {
    }



    UNSAFE_componentWillMount() {
        this.getNavLinksData();

       window.addEventListener('scroll', this.hideBar);
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.hideBar);
   }

    getNavLinksData() {
        axios.get('/api/Home/GetHeaderMenu/').then(
            response => {
                this.setState({
                    navLinks: response.data,
                });
            });
    }
    

   //Function to show and hide fixed header
   hideBar = () => {
      const { fixedHeader } = this.state;
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.scrollTop >= 200 ?
         !fixedHeader && this.setState({ fixedHeader: true })
         :
         fixedHeader && this.setState({ fixedHeader: false });
   }


    render() {

        var style = {
            color: '#666'
        }
     


        if (!this.state.navLinks.length)
            return null;
  
        return (

         <div>
            <AppBar color="inherit" position="static" className={`iron-header-wrapper iron-header-v2 ${(this.state.fixedHeader) ? 'header-fixed' : ''}`}>
                    <div className="iron-header-top py-10" style={{borderBottom: '1px solid #eee'}}>

                        <div className="container" style={{paddingLeft:'15px', paddingRight:'15px'}}>

                        <Grid container spacing={0} >

                            <Grid item xs={8} sm={6} md={3} lg={3} xl={3} className="d-flex justify-content-start align-items-center" >



                                    <div className="iron-app-logo text-md-center">
                                        <Link to="/">
                                            <img src={AppConfig.AppLogo} alt="header-logo-farmanobel" width="200px" />
                                        </Link>
                                    </div>
                            </Grid>

                            
                            <Grid item md={6} lg={7} xl={7} className=" d-flex justify-content-start align-items-center" >
                                <SearchBoxV2 />
                            </Grid>
                            

                            <Grid item xs={4} sm={6} md={3} lg={2} xl={2} className="d-flex justify-content-end align-items-center" >
                                <div className="iron-header-widgets d-flex justify-content-end align-items-center ">

                                        <Link to="/sucursales">
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                className="icon-btn mr-10"
                                                aria-label="Puntos de retiro"
                                            >
                                                <i className="material-icons">store</i>
                                            </IconButton>
                                        </Link>

                                        <Cart />
                                    <Logout style={style} />
                                </div>
                            </Grid>

                        </Grid>

                  </div>
            </div>




               <div className="bg-white">
                  <div className="container">
                     <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <div className="position-relative">
                                <HeaderMenu navLinks={this.state.navLinks} />
                                <SidebarMenu navLinks={this.state.navLinks} />
                                <SearchBox />
                            </div>
                                    

                        </Grid>
                     </Grid>
                  </div>
               </div>
                    <FixedHeader navLinks={this.state.navLinks}/>
            </AppBar>
         </div>
      );
   }
}



export default HeaderTwo;

