import React, { Fragment } from 'react';
//connect to store
import { connect } from 'react-redux';

//component
import { Grid, Button, Paper } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Cards from 'react-credit-cards';
import MaskedInput from "react-text-mask";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import { getTotalPrice } from "../../../helpers";
import { withRouter } from 'react-router';


import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            variant="standard"
            {...other}
            mask={[/\d/, /\d/, '/', /\d/, /\d/]}
        />
    );

}
const TextCVC = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            variant="standard"
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/]}
        />
    );
}



class MercadoPago extends React.Component {

    constructor(props) {
        super(props);


        this.mercadoPagoPayHandler = this.mercadoPagoPayHandler.bind(this);
        this.mpResponseHandler = this.mpResponseHandler.bind(this);

        this.onCreditCardNumberChange = this.onCreditCardNumberChange.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.getInstallments = this.getInstallments.bind(this);

        
        this.state = {
            errors: {},
            fields: {},

            creditCardInfo: {
                number: '',
                holderName: '',
                expiryDate: '',
                focused: '',
                cvc: ''
            },

            mercadoPagoInfo: {
                description: '',
                transaction_amount: 0,
                card_token_id: '',
                installment: '',
                paymentMethodId: ''
            },

            installments: []
        }
    }


    componentDidMount() {
        this.setState({
            mercadoPagoInfo: {
                transaction_amount: getTotalPrice(),
                description: '',
                docNumber: '',
                email: '',

            }

        });
    }


    onMercadoInfoChange(key, e) {
        this.setState({
            mercadoPagoInfo: {
                ...this.state.mercadoPagoInfo,
                [key]: e.target.value
            }
        })
    }

    
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;



    }


    mercadoPagoPayHandler(event) {

        this.handleValidation();

        console.log("asd");

        window.Mercadopago.setPublishableKey("APP_USR-309c3490-2032-48f0-8f1b-fadf957882bb");

        var securityCodeValue = document.getElementById("securityCode").value;

        securityCodeValue = securityCodeValue.replace(/_\s*$/, "");

        document.getElementById("securityCode").value = securityCodeValue;
        var $form = document.querySelector('#pay');

        window.Mercadopago.createToken($form, this.mpResponseHandler);
        event.preventDefault();
    }

    mpResponseHandler(status, response) {

        let currentComponent = this;

        var $form = document.querySelector('#pay');


        if (response.error) {

            var data = {
                ok: false,
            };


            this.props.submitStepThree(data);


        } else {
            var card_token_id = response.id;

            currentComponent.setState({
                mercadoPagoInfo: {
                    ...this.state.mercadoPagoInfo,
                    card_token_id: response.id
                }
            })

            const { creditCardInfo } = this.state
            const { mercadoPagoInfo } = this.state

            var data = {
                ok: true,
                description: mercadoPagoInfo.description,
                transaction_amount: mercadoPagoInfo.transaction_amount,
                card_token_id: mercadoPagoInfo.card_token_id,
                installment: mercadoPagoInfo.installment,
                docNumber: mercadoPagoInfo.docNumber,
                email: mercadoPagoInfo.email,
                creditCardInfo: creditCardInfo,
                paymentMethodId: mercadoPagoInfo.paymentMethodId
            };


            this.props.submitStepThree(data);

        }
    }

    guessPaymentMethod(event) {

        window.Mercadopago.setPublishableKey("APP_USR-309c3490-2032-48f0-8f1b-fadf957882bb");

        let cardnumber = document.getElementById("cardNumber").value;

        if (cardnumber.length >= 6) {


            let bin = cardnumber.substring(0, 6);

            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, this.setPaymentMethod);
        }
    };

    setPaymentMethod(status, response) {

        if (status === 200) {
            let paymentMethodId = response[0].id;
            let element = document.getElementById('payment_method_id');
            element.value = paymentMethodId;


            this.setState({
                mercadoPagoInfo: {
                    ...this.state.mercadoPagoInfo,
                    paymentMethodId: paymentMethodId
                }
            })


            this.getInstallments();
        }
        else {
            //alert(`payment method info error: ${response}`);
        }
    }

    getInstallments() {

        let currentComponent = this;

        window.Mercadopago.setPublishableKey("APP_USR-309c3490-2032-48f0-8f1b-fadf957882bb");

        window.Mercadopago.getInstallments({
            "payment_method_id": document.getElementById('payment_method_id').value,
            "amount": parseFloat(document.getElementById('transaction_amount').value)

        }, function (status, response) {
            if (status === 200) {

                //document.getElementById('installments').options.length = 0;


                //response[0].payer_costs.forEach(installment => {
                //    let opt = document.createElement('option');
                //    opt.text = installment.recommended_message;
                //    opt.value = installment.installments;
                //    document.getElementById('installments').appendChild(opt);
                //});


                currentComponent.setState({
                    installments: response[0].payer_costs,
                });

            } else {
                alert(`installments method info error: ${response}`);
            }
        });
    }





    onCreditCardNumberChange(e) {
        this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                number: e.target.value
            }
        })

        this.guessPaymentMethod(e);
    }


    onCreditCardExpiryDateChange(e) {
        this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                expiryDate: e.target.value
            }
        })

        var expiryDate = e.target.value;

        if (expiryDate.length >= 2) {
            let month = expiryDate.substring(0, 2);
            document.getElementById("cardExpirationMonth").value = month;
        }

        if (expiryDate.length >= 5) {
            let year = expiryDate.substring(3, 5);
            document.getElementById("cardExpirationYear").value = year;
        }
    }

    onCreditCardValueChange(key, e) {
        this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                [key]: e.target.value
            }
        })
    }

    onFocusTextFields(key) {
        this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                focused: key
            }
        })
    }


    render() {

        const { creditCardInfo } = this.state
        const { mercadoPagoInfo } = this.state


        return (
            <div className="payment-option-wrapper">
                <ValidatorForm ref="form"
                    onSubmit={this.mercadoPagoPayHandler}
                    onError={errors => console.log(errors)}
                >
                <Fragment>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={6} lg={4} className="pl-md-15">
                    


                            <TextValidator
                                id="cardNumber"
                                name="cardNumber"
                                inputProps={{ 'data-checkout': 'cardNumber' }}
                                label="Número de la tarjeta"
                                value={creditCardInfo.number}
                                onChange={(e) => this.onCreditCardNumberChange(e)}
                                variant="standard"
                                className="iron-form-input-wrap"
                                    onFocus={() => this.onFocusTextFields('number')}
                                    validators={['required', 'matchRegexp:^[0-9]+$', 'minNumber:1', 'maxStringLength:20']}
                                    errorMessages={['El número de la tarjeta es requerido', 'Solo números', 'No puede ser 0', 'Máximo 20 carácteres']}
                            />

                            <p style={{ color: '#999' }}>Sin espacios</p>


                                <TextValidator
                                id="cardholderName"
                                name="cardholderName"
                                inputProps={{ 'data-checkout': 'cardholderName' }}
                                label="Nombre y apellido"
                                value={creditCardInfo.holderName}
                                onChange={(e) => this.onCreditCardValueChange('holderName', e)}
                                variant="standard"
                                className="iron-form-input-wrap"
                                onFocus={() => this.onFocusTextFields('name')}
                                validators={['required', 'maxStringLength:20']}
                                errorMessages={['El nombre y apellido es requerido', 'Máximo 20 carácteres']}
                            />

                            <p style={{ color: '#999' }}>Tal como aparece en la tarjeta</p>

                            <FormControl className="mr-md-30 mb-30 mb-md-0 iron-form-input-wrap">
                                <InputLabel>
                                    Fecha de expiración (MM/YY)
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    value={creditCardInfo.expiryDate}
                                    onChange={(e) => this.onCreditCardExpiryDateChange(e)}
                                    onFocus={() => this.onFocusTextFields('expiry')}
                                    inputComponent={TextMaskCustom}
                                />
                            </FormControl>

                            <FormControl className="iron-form-input-wrap">
                                <InputLabel>Código de seguridad</InputLabel>
                                <Input
                                    variant="standard"
                                    id="securityCode2"
                                    name="securityCode2"
                                    autoComplete={false}
                                    value={creditCardInfo.cvc ? creditCardInfo.cvc : ''}
                                    onChange={(e) => this.onCreditCardValueChange('cvc', e)}
                                    onFocus={() => this.onFocusTextFields('cvc')}
                                    inputComponent={TextCVC}
                                />
                            </FormControl>

                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Cuotas</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="installments"
                                    name="installments"
                                    value={mercadoPagoInfo.installment}
                                    onChange={(e) => this.onMercadoInfoChange('installment', e)}
                                    onFocus={() => this.onFocusTextFields('number')}
                                >

                                    {

                                        this.state.installments.map((installment, index) => {
                                            return (
                                                <MenuItem value={installment.installments} key={index}>
                                                    {installment.recommended_message}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                                </FormControl>

                          

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} className="pl-md-15">
                            <div className="d-md-flex justify-content-center align-items-center pt-md-60 pt-30">
                                <Cards
                                    number={creditCardInfo.number}
                                    name={creditCardInfo.holderName}
                                    expiry={creditCardInfo.expiryDate}
                                    cvc={creditCardInfo.cvc ? creditCardInfo.cvc : ''}
                                    focused={creditCardInfo.focused}
                                />
                            </div>
                        </Grid>

                        <form action="/" method="post" id="pay" name="pay" >

                            <input type="hidden" name="description" id="description" value="Compra www.farmanobel.com" />
                            <input type="hidden" name="transaction_amount" id="transaction_amount" value={getTotalPrice()} />

                            <input type="hidden"
                                id="cardNumber"
                                data-checkout="cardNumber"
                                value={creditCardInfo.number} />

                            <input type="hidden"
                                id="cardholderName"
                                data-checkout="cardholderName"
                                value={creditCardInfo.holderName} />

                            <input type="hidden" id="cardExpirationMonth"
                                data-checkout="cardExpirationMonth"
                            />

                            <input type="hidden"
                                id="cardExpirationYear"
                                data-checkout="cardExpirationYear"
                            />

                            <input type="hidden"
                                id="securityCode"
                                data-checkout="securityCode"
                                value={creditCardInfo.cvc} />

                            <input type="hidden" id="installments" class="form-control" name="installments" value={mercadoPagoInfo.installment} />

                            <input type="hidden" id="docType" data-checkout="docType" value="DNI" />

                            <input type="hidden" id="docNumber" data-checkout="docNumber" value={this.props.stepOneData.docNumber} />

                            <input type="hidden" id="email" name="email" value={this.props.stepOneData.email} />

                            <input type="hidden" name="payment_method_id" id="payment_method_id" />


                        </form>

                    </Grid>

                    <Button
                        className="button btn-sm mb-40 mt-15"
                        type="submit"
                        variant="contained"
                        disabled={this.state.confirmButtonDisabled}
                        //onClick={this.mercadoPagoPayHandler}
                    >
                            Continuar
                    </Button>

                </Fragment>
                          </ValidatorForm>    
            </div>
        )
    }
}

const mapStateToProps = ({ ecommerce }) => {
    const { cart } = ecommerce;
    return { cart };
}

export default withRouter(connect(mapStateToProps)(MercadoPago));

