const footerData = {
    about: [
        {
            "path": "/sobre-nosotros",
            "menu_title": "Sobre nosotros"
        },
        {
            "path": "/terminos-y-condiciones",
            "menu_title": "Términos y condiciones"
        },
        //{
        //    "path": "/terminos-y-condiciones",
        //    "menu_title": "Política de privacidad"
        //},
        {
            "path": "/preguntas-frecuentes",
            "menu_title": "Preguntas frecuentes"
        },        
    ],
    social: [
        {
            "path": "https://www.facebook.com/farmanobelfarmacia",
            "menu_title": "Facebook"
        },
        {
            "path": "https://www.instagram.com/farmanobel/?igshid=q0otacvqh2k0",
            "menu_title": "Instagram"
        },
        {
            "path": "https://api.whatsapp.com/send?phone=5491144144111&text=Hola%2c%20me%20comunico%20desde%20www.farmanobel.com%20&source=&data=&app_absent=",
            "menu_title": "WhatsApp"
        }
    ]
}
export default footerData;
