import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
//import { Route, Switch,   } from 'react-router-dom';
//import { Router, withRouter } from 'react-router';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import axios from 'axios';


// css
import './lib/embryoCss.js';

import HeaderTwo from "./components/layouts/headers/HeaderTwo";
import FooterOne from "./components/layouts/footers/FooterOne";


import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { UserProvider  } from './userContext';

import ReactGA from 'react-ga';

import {
    AsyncSanJoseSaludComponent,
    AsyncHomePageTwoComponent,
    AsyncAccessoriesComponent,
    AsyncBrandsComponent,
    AsyncOffersComponent,
    AsyncSubcategoriesComponent,
    AsyncAboutUSComponent,
    AsyncCartComponent,
    AsyncCheckOutComponent,   
    AsyncFAQComponent,
    AsyncPaymentOptionsComponent,
    AsyncPrivacyPolicyComponent,
    AsyncTermAndConditionComponent,
    AsyncProductDetailComponent,
    AsyncProductDetail2Component,
    AsyncInvoiceComponent,
    AsyncShopComponent,
    AsyncSignInPageComponent,
    AsyncSignUpComponent,
    AsyncForgetPasswordComponent,
    AsyncUserAccountComponent,
    AsyncPageNotFoundComponent,
    AsyncRrhhComponent,    
    AsyncProfileComponent,
    AsyncSearchProductsComponent,
    AsyncLaboratoriesComponent,
    AsyncPasswordRecoveryComponent,
    SuccessComponent,
    FailureComponent,
    PendingsComponent,
    AsyncFlyerComponent,
    AsyncFolderDigitalComponent,
    AsyncReserveWithRecipeComponent,
    AsyncPharmaciesComponent,
    AsyncOrderStateComponent
} from './util/AsyncRoutes';


import { hideAlert } from "./actions/action";


import footerData from './assets/data/footerData';
import ThemeOptions from './components/ThemeOptions/ThemeOptions';
import { getToken } from './helpers/auth-helper';

import { createBrowserHistory } from "history";

const history = createBrowserHistory()


history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log("NAVEGA A : " + location.pathname)
});




class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            categories: [],
            user: '',        
        };

        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.deleteCacheAndReloadPage = this.deleteCacheAndReloadPage.bind(this);
    }



    async componentDidMount() {

        ReactGA.initialize('UA-174987733-1');






        await axios.get('/api/auth/GetAppVersion')
            .then(response => {
                var serverVersion = response.data;

                var localVersion = localStorage.getItem('FarmanobelVersion');


                if (localVersion === null) {
                    localStorage.setItem('FarmanobelVersion', serverVersion);
                    console.log('empty cookie version');
                    window.location.reload(true);
                }
                else {
                    if (serverVersion > localVersion) {
                        localStorage.setItem('FarmanobelVersion', serverVersion);
                        this.deleteCacheAndReloadPage();
                    }
                    else {
                        console.log('current version');
                    }
                }



            })
            .catch(() => {
                console.log("Error getting version");
            });





		const { darkMode,rtlLayout } = this.props;
		this.rtlLayoutOption(rtlLayout);
        this.darkModeHanlder(darkMode);

        this.getCategories();

        var token = await getToken();

        this.setState({
            user: {
                ...this.state.user,
                token: token,
            }
        });


        axios.get('/api/User/GetUserProfile', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log("app.js ON");

                this.setState({
                    user: {
                        ...this.state.user,
                        isAuth: true,
                    }
                });
            })
            .catch(() => {

                console.log("app.js OFF");

                this.setState({
                    user: {
                        ...this.state.user,
                        isAuth: false,
                    }
                });
            });
	}

    async deleteCacheAndReloadPage() {
        console.log('new version');

        if (caches) {

            const names = await caches.keys();
            console.log(names);

            await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
	}

    async getCategories() {

        await axios.get(`/api/Home/GetCategoriesForAppRoutes`)
            .then(response => {
                this.setState({ categories: response.data })
            })       
    }

	rtlLayoutOption(rtlLayout) {
      if (rtlLayout === true) {
         document.body.classList.add("rtl-layout");
      }
      else {
         document.body.classList.remove("rtl-layout");
      }
	}
	
	darkModeHanlder(darkMode) {
		if (darkMode === true) {
			document.body.classList.add("dark-mode");
		}
		else {
			document.body.classList.remove("dark-mode");
		}
	}
   
   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         window.scrollTo(0, 0);
      }
   }

   getUrl(pathname) {
      let pathArray = pathname.split('/');
      return `/${pathArray[1]}` === '/admin-panel' ? true : false;
   }


    logout() {
    
        this.props.history.push("/sign-in");

        this.setState({
            user: {
                ...this.state.user,
                isAuth: false,
                token: '',
            }
        });
    }



    login(data) {

        this.setState({
            user: {
                ...this.state.user,
                isAuth: true,
                token: data.token,
            }
        });
    }



    render() {
        const { location } = this.props;
        const { selectedLocale, alertType,theme } = this.props;

        const userValue = {
            user: this.state.user,
            logoutUser: this.logout,
            loginUser: this.login,
        }
       

        return (
                <MuiThemeProvider theme={theme}>
                    <UserProvider value={userValue}>
                        <Fragment>
         
                        <div className="app-container">
                        <Router history={history}>
                            <HeaderTwo />    
                            <Switch>

                                <Route exact path="/" component={AsyncHomePageTwoComponent} />                        
                                    {/*
                                    {
                                        this.state.categories.map((category, index) => {
                                            return (<Route exact key={index} path={category.url} component={AsyncAccessoriesComponent} />)
                                        })
                                    }
                                    {
                                        this.state.categories.map((category, index) => {
                                            return (<Route exact key={index} path={category.url + "/:id"} component={AsyncProductDetail2Component} />)
                                        })                                    
                                    }
                                    */}

                            <Route path="/shop/:categoryId/:subcategoryId?" component={AsyncAccessoriesComponent} />
                            <Route path={"/producto/:id"} component={AsyncProductDetail2Component} />

                                    {/*<Route path="/todo-bebes/:id" component={AsyncSubcategoriesComponent} /> */}

                                    
                            <Route path="/folder-digital" component={AsyncFolderDigitalComponent} />

                            <Route path="/shop/subcategoria/:id" component={AsyncSubcategoriesComponent} />
                            <Route path="/marcas/:id" component={AsyncBrandsComponent} />
                            <Route path="/laboratorios/:id" component={AsyncLaboratoriesComponent} />
                            <Route path="/buscador/:id" component={AsyncSearchProductsComponent} />
                            <Route path="/ofertas" component={AsyncOffersComponent} />
                            <Route path="/flyer" component={AsyncFlyerComponent} />
                            <Route path="/san-jose-salud" component={AsyncSanJoseSaludComponent} />
                            <Route path="/trabaja-con-nosotros" component={AsyncRrhhComponent} />                              
                            <Route path="/cart" component={AsyncCartComponent} />
                            <Route path="/check-out" component={AsyncCheckOutComponent} />
                            <Route path="/payment" component={AsyncPaymentOptionsComponent} />
                            <Route path="/final-receipt" component={AsyncInvoiceComponent} />                  
                            <Route path="/reserve-with-recipe" component={AsyncReserveWithRecipeComponent} />                                                   
                            <Route path="/sucursales" component={AsyncPharmaciesComponent} />                                    
                            <Route path="/sobre-nosotros" component={AsyncAboutUSComponent} />
                            <Route path="/terminos-y-condiciones" component={AsyncTermAndConditionComponent} />
                            <Route path="/privacy-policy" component={AsyncPrivacyPolicyComponent} />
                            <Route path="/preguntas-frecuentes" component={AsyncFAQComponent} />
                            <Route path="/sign-in" component={AsyncSignInPageComponent} />
                            <Route path="/sign-up" component={AsyncSignUpComponent} />
                            <Route path="/forget-password" component={AsyncForgetPasswordComponent} />
                            <Route path="/account" component={AsyncProfileComponent} />
                            {/*<Route path="/success(/:collection_id)(/:collection_status)(/:payment_id)(/:status)(/:external_reference)(/:payment_type)(/:merchant_order_id)(/:preference_id)(/:site_id)(/:processing_mode)(/:merchant_account_id)" component={SuccessComponent} />*/}
                            <Route path="/success" component={SuccessComponent} />
                            <Route path="/failure" component={FailureComponent} />
                            <Route path="/pendings" component={PendingsComponent} />

                            <Route path="/estado-pedido/:id" component={AsyncOrderStateComponent} />



                                    
                            <Route path="/recuperar-usuario/:id" component={AsyncPasswordRecoveryComponent} />
                                  
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                            <Route path="*" component={AsyncPageNotFoundComponent} />




                        </Switch>

                        <FooterOne data={footerData} />
            </Router>

                        <SweetAlert
                            success={alertType === 'success'}
                            error={alertType === 'error'}
                            title=''
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="warning"
                            className="iron-alert-box"
                            show={this.props.showAlert}
                            onConfirm={this.props.hideAlert}
                            onCancel={this.props.hideAlert}
                            closeOnClickOutside
                        >
                            {this.props.alertMessage}
                        </SweetAlert>

                        </div>
                  
                    <ThemeOptions url={this.getUrl(location.pathname)} />
                        </Fragment>
                    </UserProvider >
                </MuiThemeProvider>
    )
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   const { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout } = appSettings;
   return { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout };
}

export default connect(mapStateToProps, {
   hideAlert
})(App);