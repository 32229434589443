/**
 * header search widget
 */
import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

class SearchBox extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);


        this.state = {
            isSearchFormOpen: false,
            inputText: '',

        };
    }

    /**
     * Method To Handle Search Form 
     */
    handleSearchForm(isOpen) {
        this.setState({ isSearchFormOpen: isOpen });
    };


    handleKeyDown(e) {
        if (e.key === 'Enter') {
            var inputText = this.state.inputText;

            this.setState({
                inputText: ''
            })



            this.props.history.push("/buscador/" + inputText);

            this.handleSearchForm(false)
        }
    }

    onChange = (event) => {
        this.setState({
            inputText: event.target.value
        })
    }


    render() {
        const { isSearchFormOpen } = this.state;
        return (
            <div className={classNames("iron-search-box ", { 'active-search-form': isSearchFormOpen })}>
                <IconButton
                    onClick={() => this.handleSearchForm(true)}
                    color="inherit"
                    color="primary"
                >
                    <SearchIcon />
                </IconButton>

                <div className="search-form px-15 justify-content-between align-items-center" >
                    <div className="input-wrap">
                        <Input
                            fullWidth
                            placeholder="¿Qué estás buscando?"
                            disableUnderline
                            onKeyDown={this.handleKeyDown}
                            value={this.state.inputText}
                            onChange={this.onChange}                           
                        />
                    </div>
                    <IconButton
                        onClick={() => this.handleSearchForm(false)}
                        className="close-btn"
                    >
                        <i className="material-icons">
                            close
                        </i>
                    </IconButton>
                </div>

            </div>
        );
    }
}

export default withRouter(SearchBox);
