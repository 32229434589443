import {
    COLLAPSED_SIDEBAR,
    RTL_LAYOUT,
    ADD_TO_CART,
    ADD_TO_CART_QUANTITY,
    ADD_TO_WISHLIST,
    SHOW_ALERT,
    HIDE_ALERT,
    REMOVE_FROM_CART,
    UPDATE_PRODUCT_QUANTITY,
    CHANGE_CURRENCY,
    SET_LANGUAGE,
    FINAL_PAYMENT,
    MOVE_WISHLISTITEM_TO_CART,
    REMOVE_FROM_WISHLIST,
    DELETE_USER,
    ADD_NEW_USER,
    DARK_MODE,
    INITIALIZE_CART,
    UPDATE_SHIPPING_COST,
    LOGIN,
    ORDER_CODE,
    CLEAR_CART,
    SET_RETIRE_POINT

} from './types';


export const addProductItem = (data, alertMessage) => (dispatch) => {

    console.log("action.js 1 add product data: ", data);

    let addProductData = {
        productID: data.objectID,
        quantity: data.minimumAmount,
        price: data.price,

        minimumAmount: data.minimumAmount,
        discountLabelFirstText: data.discountLabelFirstText,
        discountLabelSecondText: data.discountLabelSecondText,
        haveDiscount: data.haveDiscount,
        priceWithDiscount: data.priceWithDiscount
    }



    const response = fetch('/api/ShoppingCart/AddProduct', {
        method: 'POST',
        body: JSON.stringify(addProductData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            console.log("action.js 2 addProductItem data response: ", myJson);

            data.id = myJson.shoppingCartID;
            data.totalPrice = myJson.totalPrice;
            dispatch({
                type: ADD_TO_CART,
                payload: data
            })
        });


}

export const addProductItemQuantity = (data, alertMessage) => (dispatch) => {

    console.log("action.js 1 addProductItemQuantity: ", data);

    let addProductData = {
        productID: data.objectID,
        quantity: data.quantity,
        price: data.price,

        minimumAmount: data.minimumAmount,
        discountLabelFirstText: data.discountLabelFirstText,
        discountLabelSecondText: data.discountLabelSecondText,
        haveDiscount: data.haveDiscount,
        priceWithDiscount: data.priceWithDiscount
    }



    const response = fetch('/api/ShoppingCart/AddProduct', {
        method: 'POST',
        body: JSON.stringify(addProductData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(function (response) {
        return response.json();
    })
        .then(function (myJson) {
            console.log("action.js 2 addProductItemQuantity data response: ", myJson);

            data.id = myJson.shoppingCartID;
            data.totalPrice = myJson.totalPrice;
            dispatch({
                type: ADD_TO_CART_QUANTITY,
                payload: data
            })
        });


}

export const showAlert = (message, type) => ({
   type: SHOW_ALERT,
   payload: { message, type }
})

export const hideAlert = () => ({
   type: HIDE_ALERT,
})

export const addToWishlist = (data, alertMessage) => (dispatch) => {
   dispatch({
      type: ADD_TO_WISHLIST,
      payload: data
   })
}

export const moveWishlistItemToCart = () => ({
   type: MOVE_WISHLISTITEM_TO_CART
})

export const deleteItemFromWishlist = (data) => ({
   type: REMOVE_FROM_WISHLIST,
   payload: data
})

export const removeProductItem = (data) => (dispatch) => {
       
    const response = fetch('/api/ShoppingCart/RemoveProduct', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    dispatch({
        type: REMOVE_FROM_CART,
        payload: data
    })
}

export const updateProductQuantity = (data) => (dispatch) => {

    let newData = data.cartItem;
    newData.quantity = data.newQuantity;


    const responseupdateProductQuantity = fetch('/api/ShoppingCart/UpdateProductQuantity', {
        method: 'POST',
        body: JSON.stringify(newData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    dispatch({
        type: UPDATE_PRODUCT_QUANTITY,
        payload: data
    })
}

export const changeCurrency = (currency) => ({
   type: CHANGE_CURRENCY,
   payload: currency
})

export const setLanguage = (locale) => ({
   type: SET_LANGUAGE,
   payload: locale
})

export const finalPayment = (history) => (dispatch) => {
   history.push('/final-receipt');
   dispatch({ type: FINAL_PAYMENT });

}

export const rtlLayoutAction = (isRtlLayout) => ({
   type: RTL_LAYOUT,
   payload: isRtlLayout
});

//Redux Action To Emit Collapse Sidebar
export const collapsedSidebarAction = (isCollapsed) => ({
   type: COLLAPSED_SIDEBAR,
   isCollapsed
});

//======== Admin-panel actions ========

//add user
export const addNewUser = (data) => ({
   type: ADD_NEW_USER,
   payload: data
})

//delete user 
export const deleteUser = (data) => ({
   type: DELETE_USER,
   payload: data
})

//======== Customiser ========
/**
 * Redux Action To Emit Dark Mode
 * @param {*boolean} isDarkMode 
 */
export const darkModeAction = (isDarkMode) => ({
	type: DARK_MODE,
	payload: isDarkMode
});



export const initializeCart = (data) => ({
    type: INITIALIZE_CART
    , payload: data
})



export const clearCart = (data) => ({
    type: CLEAR_CART
    , payload: data
})


export const updateShippingCost = (data) => ({
    type: UPDATE_SHIPPING_COST
    , payload: data
})

export const setOrderCode = (data) => ({
    type: ORDER_CODE
    , payload: data
})

export const setRetirePoint = (data) => ({
    type: SET_RETIRE_POINT
    , payload: data
})



