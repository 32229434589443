/**
 * App Light Theme
 */
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
   palette: {
		type: "light",
      primary: {
          main: '#0074bc'
      },
      secondary: {
          main: '#ff7f27'
      }
	}
	,overrides: {
		MuiTableRow:{
			root: {
                height: 48
            },
            MuiButton: {
                root: { 
                    textTransform: 'none'
                },
            },
		},
	},
});

export default theme;