/**
 * site footer one component
 */
/* eslint-disable */
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';

//App Config
import AppConfig from '../../../constants/AppConfig';
import mercadoPago from '../../../routes/check-out/payment/mercadoPago';

const localVersion = localStorage.getItem('FarmanobelVersion');


export default function FooterOne(props) {

    const { social, about } = props.data;

    useEffect(() => {

    

        //const script = document.createElement('script');

        //script.src = "https://seal.godaddy.com/getSeal?sealID=6MsiDg52wXRb8FfYfoJITqzbMBfniUJeOHVtlXL607HNimkXxoICAyHzlI5L";
        //script.async = true;

        //var elemento = document.getElementById("siteseal");

        //elemento.appendChild(script);

        //return () => {
        //    document.body.removeChild(script);
        //}
    }, []);


   return (
      <footer className="iron-footer-v1">
         <div className="iron-footer-top ">
            <div className="container" >
                   <Grid container spacing={3} alignItems="center" >
                  <Grid item xs={12} md={12} lg={4} xl={4} >
                     <div>
                        <div className="footer-widget-title mb-30">                             
                        </div>
                            <div className="footer-content">
                        </div>
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                     <div>
                        <div className="footer-widget-title mb-30">
                           <b>Sobre nosotros</b>
                        </div>
                               <List component="nav" className="iron-footer-menu-list">
                           {
                              about.map((aboutdata, key) => {
                                 return (
                                    <li key={key} className="list-item">
                                         <Link to={aboutdata.path} style={{ color: '#999' }}>
                                          {aboutdata.menu_title}
                                       </Link>
                                    </li>
                                 )
                              })
                                       }
                        </List>
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                     <div>
                        <div className="footer-widget-title mb-30">
                        </div>
                            <List component="nav" className="iron-footer-menu-list">                                
                        </List>
                     </div>
                  </Grid>
                
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                     <div>
                        <div className="mb-30">
                           <b>Redes sociales</b>
                        </div>
                               <List component="nav" className="iron-footer-menu-list">
                           {
                              social.map((social, key) => {
                                 return (
                                    <li key={key} className="list-item">
                                         <a href={social.path} target="blank" style={{color:'#999'}}>
                                          {social.menu_title}
                                       </a>
                                    </li>
                                 )
                              })

                                       
                           }

                        </List>
                     </div>
                       </Grid>

                       <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                           <center>
                           <a href="http://qr.afip.gob.ar/?qr=uW_xdeaEncKHvnbqs_jWIw,," target="_F960AFIPInfo">
                               <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0"  width="75px" alt="data-fiscal"/ >
                               </a>
                               </center>
                       </Grid>
                           
               </Grid>
            </div>
           </div>


         <div className="iron-footer-bottom">
            <div className="container">
                   <Grid container spacing={2} alignItems="center" >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="text-center text-lg-left mb-30 mb-lg-0">
                           <center>

                           <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/785X40.jpg"
                               title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"
                                   width="785" height="40" />
                               </center>
                    </Grid>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="text-center">                           
                        <img src={require('../../../assets/images/ssl.png')} alt='sitio seguro' width="100px" title="Sitio seguro"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="text-center text-lg-left mb-30 mb-lg-0">
                        <div className="iron-copyright text-lg-right text-center">
                               <p className="mb-0" style={{color:'#999'}}>{AppConfig.CopyrightText} {"v 0." + localVersion + ".28"}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
         </div>
      </footer>
   )
}
