/**
 * AsyncRoutes
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

//app loader
import ContentLoader from '../components/global/loaders/ContentLoader';


// Home
const AsyncSanJoseSaludComponent = Loadable({
    loader: () => import('../routes/san-jose-salud'),
    loading: () => <ContentLoader />,
});


// Home
const AsyncHomePageTwoComponent = Loadable({
   loader: () => import('../routes/home-two'),
   loading: () => <ContentLoader />,
});

// Home
const AsyncSearchProductsComponent = Loadable({
    loader: () => import('../routes/shop-by-search'),
    loading: () => <ContentLoader />,
});



// accessories
const AsyncAccessoriesComponent = Loadable({
    loader: () => import('../routes/shop-by-categories/'),
    loading: () => <ContentLoader />,
});

// subcategories
const AsyncSubcategoriesComponent = Loadable({
    loader: () => import('../routes/shop-by-subcategories/'),
    loading: () => <ContentLoader />,
});

// shop-by-brands
const AsyncBrandsComponent = Loadable({
    loader: () => import('../routes/shop-by-brands/'),
    loading: () => <ContentLoader />,
});

// shop-by-offers
const AsyncOffersComponent = Loadable({
    loader: () => import('../routes/shop-by-offers/'),
    loading: () => <ContentLoader />,
});

// shop-by-flyer
const AsyncFlyerComponent = Loadable({
    loader: () => import('../routes/shop-by-flyer/'),
    loading: () => <ContentLoader />,
});

// shop-by-laboratories
const AsyncLaboratoriesComponent = Loadable({
    loader: () => import('../routes/shop-by-laboratories/'),
    loading: () => <ContentLoader />,
});


// reserve-with-recipe
const AsyncReserveWithRecipeComponent = Loadable({
    loader: () => import('../routes/reserve-with-recipe/'),
    loading: () => <ContentLoader />,
});

// offers
const AsyncRrhhComponent = Loadable({
    loader: () => import('../routes/rrhh/'),
    loading: () => <ContentLoader />,
});



// about us
const AsyncAboutUSComponent = Loadable({
   loader: () => import('../routes/about-us'),
   loading: () => <ContentLoader />,
});

// cart
const AsyncCartComponent = Loadable({
   loader: () => import('../routes/cart'),
   loading: () => <ContentLoader />,
});

// checkout
const AsyncCheckOutComponent = Loadable({
   loader: () => import('../routes/check-out'),
   loading: () => <ContentLoader />,
});


// offers
const AsyncFolderDigitalComponent = Loadable({
    loader: () => import('../routes/folder-digital/'),
    loading: () => <ContentLoader />,
});



// Faq
const AsyncPharmaciesComponent = Loadable({
    loader: () => import('../routes/pharmacies'),
    loading: () => <ContentLoader />,
});




// Faq
const AsyncFAQComponent = Loadable({
   loader: () => import('../routes/faq'),
   loading: () => <ContentLoader />,
});

// page404
const AsyncPageNotFoundComponent = Loadable({
   loader: () => import('../routes/page-404'),
   loading: () => <ContentLoader />,
});

// Payment
const AsyncPaymentOptionsComponent = Loadable({
   loader: () => import('../routes/check-out/payment'),
   loading: () => <ContentLoader />,
});

// privacy policy
const AsyncPrivacyPolicyComponent = Loadable({
   loader: () => import('../routes/privacy-policy'),
   loading: () => <ContentLoader />,
});

// Terms and Condition
const AsyncTermAndConditionComponent = Loadable({
   loader: () => import('../routes/term-and-condition'),
   loading: () => <ContentLoader />,
});

// product detail
const AsyncProductDetailComponent = Loadable({
   loader: () => import('../routes/products'),
   loading: () => <ContentLoader />,
});


// product detail2
const AsyncProductDetail2Component = Loadable({
    loader: () => import('../routes/product'),
    loading: () => <ContentLoader />,
});

// invoice
const AsyncInvoiceComponent = Loadable({
   loader: () => import('../routes/check-out/final-receipt'),
   loading: () => <ContentLoader />,
});



const AsyncProfileComponent = Loadable({
    loader: () => import('../routes/profile'),
    loading: () => <ContentLoader />,
});


// SignIn
const AsyncSignInPageComponent = Loadable({
   loader: () => import('../routes/session/sign-in'),
   loading: () => <ContentLoader />,
});

// Register
const AsyncSignUpComponent = Loadable({
   loader: () => import('../routes/session/sign-up'),
   loading: () => <ContentLoader />,
});

// ForgetPassword
const AsyncForgetPasswordComponent = Loadable({
   loader: () => import('../routes/session/forget-password'),
   loading: () => <ContentLoader />,
});


const AsyncPasswordRecoveryComponent = Loadable({
    loader: () => import('../routes/session/recuperar-usuario'),
    loading: () => <ContentLoader />,
});



const SuccessComponent = Loadable({
    loader: () => import('../routes/mp-success'),
    loading: () => <ContentLoader />,
});

const FailureComponent = Loadable({
    loader: () => import('../routes/mp-failure'),
    loading: () => <ContentLoader />,
});


const PendingsComponent = Loadable({
    loader: () => import('../routes/mp-pendings'),
    loading: () => <ContentLoader />,
});


// product detail
const AsyncOrderStateComponent = Loadable({
    loader: () => import('../routes/order-state'),
    loading: () => <ContentLoader />,
});



export {


    AsyncHomePageTwoComponent,

    AsyncAccessoriesComponent,
    AsyncBrandsComponent,
    AsyncLaboratoriesComponent,
    AsyncOffersComponent,
    AsyncFlyerComponent,
    AsyncSearchProductsComponent,
    AsyncSubcategoriesComponent,




   AsyncCartComponent,
   AsyncCheckOutComponent,
   AsyncInvoiceComponent,

    AsyncReserveWithRecipeComponent,
    AsyncSanJoseSaludComponent,
    AsyncRrhhComponent,

   AsyncAboutUSComponent,   
   AsyncFAQComponent,
   
   AsyncPaymentOptionsComponent,
   AsyncPrivacyPolicyComponent,
   AsyncTermAndConditionComponent,
   AsyncProductDetailComponent,
    AsyncProductDetail2Component,

   
   AsyncSignInPageComponent,
   AsyncSignUpComponent,
    AsyncForgetPasswordComponent,
    AsyncPasswordRecoveryComponent,

    AsyncPageNotFoundComponent,
    AsyncProfileComponent,
    SuccessComponent,
    FailureComponent,
    PendingsComponent,
    AsyncFolderDigitalComponent,


    AsyncPharmaciesComponent,
    AsyncOrderStateComponent
}