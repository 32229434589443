import Axios from "axios";

const TOKEN_KEY = "FARMANOBEL_TOKEN";

export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}


export async function getToken() {
    return localStorage.getItem(TOKEN_KEY);

 

    //var result = {
    //    isValid: null,
    //    token: ''
    //}

    //if (token === '') {
    //    result.isValid = false;
    //}
    //else {
    //    var data = {
    //        token: token,
    //    }

        //let res = await Axios.post('/api/Auth/ValidateCurrentToken', data.token)
        //    .then(response => {
        //        console.log("0. auth-helper response: ", response.data);

        //        result.isValid = response.data;
        //        result.token = token;

        //    })
        //    .catch(() => {
        //        result.isValid = false;
        //        result.token = 'FALSE';

        //    }).finally(() => {
        //    });;

        //console.log("1. auth-helper result: ", result);
    //}

    //return result;
}

export function deleteToken(e) {
    e.preventDefault();
    localStorage.removeItem(TOKEN_KEY);
    //window.location = "/";
}

export async function getCurrentUser() {
    if (!getToken()) return false;
    try {
        let response = await Axios.get("/api/v1/auth/current");
        return response.data;
    } catch (error) {
        return false;
    }
}

export function initAxiosInterceptors() {
    Axios.interceptors.request.use(config => {
        const token = getToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    Axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                deleteToken();
                window.location = "/";
            } else {
                return Promise.reject(error);
            }
        }
    );
}