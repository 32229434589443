/**
 * Sidebar menu component
 */
import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import { List } from '@material-ui/core';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

//component
import MenuListItem from './MenuListItem';

class SideBarMenuFixedHeader extends React.Component {
    constructor(props) {
        super(props);
    }

   state = {
       open: false,
       open2: false,


   };

   /**
    * function for toggle sidebar drawer
    */
   toggleDrawer() {
      this.setState({
         open: !this.state.open
      });
   };

   /**
    * function for close sidebar drawer
    */
   closeDrawer() {
      this.setState({
         open: false
      });
   }

    handleClick = (open2) => {
        this.setState({
            open2: !open2,
        })
    };

   render() {

       if (this.props.navLinks == undefined)
           return null;

      return (
          <div className="py-10 text-left iron-sidebar-nav-wrap">
              <IconButton onClick={this.toggleDrawer.bind(this)} className="nav-menu-icon">
                  <i className="material-icons" style={{ color: '#FFF' }}>menu</i>
              </IconButton>
              <SwipeableDrawer
                  open={this.state.open}
                  onClose={this.toggleDrawer.bind(this)}
                  onOpen={this.toggleDrawer.bind(this)}
                  className="sidebar-area"
              >
                  <Scrollbars
                      autoHide
                      autoHideDuration={100}
                      style={{ width: 280, height: 'calc(100vh - 2rem)' }}
                  >
                      <div className="vertical-menu py-40">


                          <List component="nav" className="iron-sidebar-menu">
                              {this.props.navLinks.map((NavLink, index) => (
                                  <div>

                                      { NavLink.categorias != null ?
                                          <div>
                                              <ListItemButton onClick={() => this.handleClick(this.state.open2)} className={this.state.open2 ? "selected" : "non-selected"}>
                                                  <ListItemText primary={NavLink.menu_title} />
                                                  {this.state.open2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                              </ListItemButton>

                                              <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
                                                  <List component="div" disablePadding>
                                                      {
                                                          NavLink.categorias.map((categoria, index) => (
                                                              <MenuListItem
                                                                  menu={categoria}
                                                                  baseUrl='/shop/'
                                                                  key={index}
                                                                  closeDrawer={() => this.closeDrawer()}
                                                              />
                                                          ))
                                                      }
                                                  </List>
                                              </Collapse>
                                          </div>
                                          :
                                          <MenuListItem
                                              menu={NavLink}
                                              baseUrl=''
                                              key={index}
                                              closeDrawer={() => this.closeDrawer()}
                                          />
                                      }
                                  </div>
                              ))}
                          </List>
                      </div>
                  </Scrollbars>
              </SwipeableDrawer>
          </div>
      );
   }
}

export default SideBarMenuFixedHeader;


