/* eslint-disable */
/**
 * Header menu component
 */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Masonry from "@mui/lab/Masonry";

import { FaWhatsapp   } from 'react-icons/fa';




class HeaderMenu extends React.Component {

    constructor(props) {
        super(props);


    }

    test(){
    }



    render() {
        if (this.props.navLinks == undefined)
            return null;
         
        return (
            <div className="horizontal-menu">
                <ul className="d-inline-block iron-header-menu mb-0">
                    {this.props.navLinks.map((navLink, index) => {


                        //tiene subitems
                        if (navLink.categorias && navLink.categorias != null) {
                            return (
                                <li key={index} className={classnames({ 'mega-menu': navLink.mega })}>
                                    <a href="#">
                                        {navLink.menu_title}  
                                        

                                    </a>
                                    {(navLink.type && navLink.type === 'subMenu') ?
                                        <Fragment>
                                         
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {navLink.categorias !== null &&

                                                <Grid id="con_grid" container className="sub-menu mb-0 d-flex" style={{padding:'1rem'}}>
                                                    <Masonry columns={5} spacing={2}>

                                                    {navLink.categorias.map((categoria, index) =>

                                                        <Grid item key={index}>
                                                                <ListItem>                                                            
                                                                    <Link to={`/shop/${categoria.path}`}>
                                                                        <p style={{ fontSize: '1.1rem', marginTop: '0', marginBottom: '0', fontWeight: '700' }}>{categoria.menu_title}</p>
                                                                    </Link>
                                                                </ListItem>
                                                            <List>
                                                                {categoria.subcategorias.map((subcategoria, index) =>
                                                                    <ListItem onClick={this.test}>                                                                    
                                                                        <Link to={`/shop/${categoria.path}/${subcategoria.path}`}>
                                                                            <p style={{ color: '#666', fontSize: '0.85rem', marginTop: '0.2rem', marginBottom: '0.6rem' }}>{subcategoria.menu_title}</p>   
                                                                        </Link>
                                                                    </ListItem>
                                                                )}
                                                                </List>

                                                        </Grid>
                                                    )
                                                    }
                                                    </Masonry>
                                                </Grid>
                                            }
                                        </Fragment>
                                    }
                                </li>
                            )
                        }
                        if (navLink.menu_title === 'WhatsApp') {
                            return (

                                <li key={index} className={classnames({ 'mega-menu': navLink.mega })}>
                                    <a href="#">
                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} />  WhatsApp
                                    </a>
                                    <Fragment>
                                        <ul className="sub-menu mb-0">
                                            <Fragment key={index}>
                                                <li style={{padding:'4px'}}>
                                                    <a style={{ }} target="_blank" href="https://wa.me/5491156008030/?text=Hola%2C%20me%20comunico%20desde%20www.farmanobel.com%20" rel="noopener">
                                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} /> Hudson
                                                    </a>
                                                </li>  
                                                <li style={{ padding: '4px' }}>
                                                    <a style={{  }} target="_blank" href="https://wa.me/5491144144111/?text=Hola%2C%20me%20comunico%20desde%20www.farmanobel.com%20" rel="noopener">
                                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} /> Ingeniero J. Allan
                                                    </a>
                                                </li>
                                                <li style={{ padding: '4px' }}>
                                                    <a style={{  }} target="_blank" href="https://wa.me/5492215413918/?text=Hola%2C%20me%20comunico%20desde%20www.farmanobel.com%20" rel="noopener">
                                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} /> 5 Esquinas
                                                    </a>
                                                </li>

                                                <li style={{ padding: '4px' }}>
                                                    <a style={{  }} target="_blank" href="https://wa.me/5491135577570/?text=Hola%2C%20me%20comunico%20desde%20www.farmanobel.com%20" rel="noopener">
                                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} /> El Pato
                                                    </a>
                                                </li>
                                                <li style={{ padding: '4px' }}>
                                                    <a style={{}} target="_blank" href="https://wa.me/5491155872047/?text=Hola%2C%20me%20comunico%20desde%20www.farmanobel.com%20" rel="noopener">
                                                        <FaWhatsapp style={{ fontSize: '25px', color: '#128C7E' }} /> Plastino
                                                    </a>
                                                </li>
                                            </Fragment>
                                        </ul>
                                    </Fragment>
                                </li>

                            )
                        } else {
                            return (
                                <li key={index}>
                                    <Link to={navLink.path}>
                                        {navLink.menu_title} 
                                    </Link>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        );
    }
}

export default HeaderMenu;