
const localStorageCartKey = 'farmanobelCart';

export const getCart = () =>{
    try{
  
        return localStorage.getItem(localStorageCartKey) === null ? [] : JSON.parse(localStorage.getItem(localStorageCartKey))
    
    }catch(error){
        return [];
    }


};

export const saveCart = (state) => {
    try {    
        const serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageCartKey, serializedState);  
    } catch 
    {   
     // ignore write errors
    }
}

export const removeCart = () => {
    try {    
        localStorage.removeItem(localStorageCartKey);  
    } catch 
    {   
     // ignore write errors
    }
}