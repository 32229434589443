import React, { useState } from "react";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';


import { Scrollbars } from 'react-custom-scrollbars';
import { List } from '@material-ui/core';
import SearchV2 from '../headers/SearchBoxV2';

import { Search, FileCopy } from "@mui/icons-material";



import Collapse from '@material-ui/core/Collapse';


import ListItemButton from '@mui/material/ListItemButton';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import InboxIcon from '@material-ui/icons/Inbox';



import SendIcon from '@material-ui/icons/Send';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import StarBorder from '@material-ui/icons/StarBorder';
//component
import MenuListItem from './MenuListItem';
import Input from '@material-ui/core/Input';

import { withRouter } from 'react-router-dom';


const SidebarMenu = withRouter((props) => {



    const [openDrawer, setOpenDrawer] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [query, setQuery] = useState('');




    const closeDrawer = () => {
        console.log("closeDrawer");
        setOpenDrawer(false)
    }

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer)
    }

    const enterCallBack = () => {
        console.log("enterCallBack");

        closeDrawer()

    }

    const handleClick = () => {
        setOpenMenu(!openMenu)
    };


    const handleDocumentKeyDown = (event) => {
        console.log(event)
    }


    const handleKeyDown = (e) => {

        if (e.key === 'Enter' || e.key === '.') {
            search();
        }
    }


    const onChange = (event) => {
        setQuery(event.target.value);
    }

    const search = () => {
        var inputText = query;
        setQuery('');
        props.history.push("/buscador/" + inputText);
        closeDrawer();

    }


    return (
        <div className="py-10 text-left iron-sidebar-nav-wrap">
            <IconButton onClick={toggleDrawer} className="nav-menu-icon">
                <i className="material-icons" style={{ color: '#666' }}>menu</i>
            </IconButton>
            <SwipeableDrawer
                open={openDrawer}
                onClose={closeDrawer}
                onOpen={openDrawer}
                className="sidebar-area"
                onClick={(event) => { event.stopPropagation() }}
            >
                <Scrollbars
                    autoHide
                    autoHideDuration={100}
                    style={{ width: 280, height: 'calc(100vh - 2rem)' }}
                >
                    <div className="vertical-menu py-40">


                     



                        <List component="nav" className="iron-sidebar-menu">
                            {props.navLinks.map((NavLink, index) => (
                                <div key={index}>

                                    { NavLink.categorias != null ?
                                        <div>
                                            <ListItemButton onClick={handleClick} className={openMenu ? "selected" : "non-selected"}>
                                                <ListItemText primary={NavLink.menu_title} />
                                                {openMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </ListItemButton>

                                            <Collapse in={openMenu} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        NavLink.categorias.map((categoria, index) => (
                                                            <MenuListItem
                                                                menu={categoria}
                                                                baseUrl='/shop/'
                                                                key={index}
                                                                closeDrawer={() => closeDrawer()}
                                                            />
                                                        ))
                                                    }
                                                </List>
                                            </Collapse>
                                        </div>
                                        :
                                        <MenuListItem
                                            menu={NavLink}
                                            baseUrl=''
                                            key={index}
                                            closeDrawer={() => closeDrawer()}
                                        />
                                    }
                                </div>
                            ))}
                        </List>
                    </div>
                </Scrollbars>
            </SwipeableDrawer>
        </div>
    );


});

export default SidebarMenu;