// import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// configureStore 
import { configureStore } from './store/index';

import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';


// store
export const store = configureStore();


ReactDOM.render(
    <ClearCacheProvider auto={true}>
  
       <Provider store={store}>
          <BrowserRouter>
             <Switch>
                <Route path="/" component={App} />
             </Switch>
          </BrowserRouter>
            </Provider>

    </ClearCacheProvider>
   , document.getElementById('root'));

registerServiceWorker();
